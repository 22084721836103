.logo {
  display: inline-block;
  color: inherit;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transform: rotate(1.5deg);
  transition: transform 600ms ease-out 200ms;
  /* TODO: Replace with more accessible solution. */
  outline: none;

  &:hover {
    transform: rotate(0deg);
    transition: transform 600ms ease-out;
  }

  .title {
    font-size: var(--font-xl);
    line-height: var(--lh-xl);
    white-space: nowrap;
  }

  .subtitle {
    margin-top: calc(-1 * 5.5rem / 16);
    font-size: var(--font-md);
    line-height: var(--lh-md);
    white-space: nowrap;
  }
}

.callout {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transform: rotate(-4deg);

  > div {
    display: contents;
  }

  .title {
    font-weight: bold;
    font-size: var(--font-lg);
    line-height: var(--lh-lg);
    max-width: 12ch;
  }

  .subtitle {
    font-family: var(--font-mono);
    font-size: var(--font-xl);
    line-height: var(--lh-xl);
    text-transform: uppercase;
  }

  .badge {
    font-weight: bold;
    font-size: var(--font-md);
    line-height: var(--lh-md);
    background-color: var(--color-white);
    border: 4.5px solid;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    padding: 5px 25px;
    margin: -4px;
  }
}

@keyframes blinkA {
    0% { opacity: 1; }
   40% { opacity: 1; }
   50% { opacity: 0; }
   90% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes blinkB {
    0% { opacity: 0; }
   40% { opacity: 0; }
   50% { opacity: 1; }
   90% { opacity: 1; }
  100% { opacity: 0; }
}

.blinker {
  display: grid;
  grid-template-columns: max-content;
  grid-template-rows: max-content;
  grid-template-areas: "x";
  align-items: center;
  justify-items: center;

  > * {
    grid-area: x;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;

    &:nth-child(1) {
      animation-name: blinkA;
    }

    &:nth-child(2) {
      animation-name: blinkB;
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: -18;
  }
}

.border {
  width: 100%;
  height: 2px;
  animation: dash 5s linear infinite;
}

.overlayCallout {
  display: none;
  position: relative;
  top: calc(-30rem / 16);
  height: 0;
  z-index: 2;
  overflow: visible;
  pointer-events: none;

  .overlayCalloutInner {
    display: grid;
    gap: var(--gutter);
    grid-template-columns: repeat(24, 1fr);
    grid-template-areas:
      ". . . . . . . . . . . . . . call call call . . . . . . .";
    padding: 10px;
  }

  .callout {
    grid-area: call;
    transform: rotate(6deg);

    .title {
      max-width: 9ch;
    }
  }

  @media (min-width: calc(1280em / 16)) {
    display: block;
  }

  @media (min-width: calc(1680em / 16)) {
    .overlayCalloutInner {
      grid-template-areas:
        ". . . . . . . . . . . . . . . . call call call . . . . .";
    }
  }

  @media (min-width: calc(2560em / 16)) {
    .overlayCalloutInner {
      grid-template-areas:
        ". . . . . . . . . . . . . . call call call . . . . . . .";
    }
  }
}
