.errorTemplate {
  padding: 0;
  background-color: var(--color-white);
  color: var(--color-blue-2);

  main {
    padding: 5px 20px;
    display: grid;
    gap: var(--gutter);
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: max-content 1fr max-content;
    grid-template-areas:
      ". . . ca1 ca1 ca1"
      ". . . . . ."
      "txt txt txt txt txt txt";

    .callout:nth-of-type(1) { grid-area: ca1; transform: rotate(2deg); }
    .callout:nth-of-type(2) { grid-area: ca2; }

    p {
      grid-area: txt;
    }

    @media (max-width: calc(767.98em / 16)) {
      .callout:nth-of-type(2) {
        display: none;
      }
    }

    @media (min-width: calc(768em / 16)) {
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas:
        ". . . . ca1 ca1 ca1 ca1 ca2 ca2 ca2 ca2"
        ". . . . . . . . . . . ."
        "txt txt txt txt txt txt txt txt txt txt txt txt";
    }

    @media (min-width: calc(1280em / 16)) {
      grid-template-columns: repeat(24, 1fr);
      grid-template-areas:
        ". . . . . . . . . . . . . ca1 ca1 ca1 ca1 . ca2 ca2 ca2 ca2 . ."
        ". . . . . . . . . . . . . . . . . . . . . . . ."
        "txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt";
    }

    @media (min-width: calc(1680em / 16)) {
      grid-template-areas:
        ". . . . . . . . . . . . . . . . ca1 ca1 ca1 ca2 ca2 ca2 . ."
        ". . . . . . . . . . . . . . . . . . . . . . . ."
        "txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt";
    }

    @media (min-width: calc(2560em / 16)) {
      grid-template-areas:
        ". . . . . . . . . . . . . . . . . . . ca1 ca1 ca2 ca2 ."
        ". . . . . . . . . . . . . . . . . . . . . . . ."
        "txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt txt";
    }
  }
}
