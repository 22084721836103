.legal {
  padding: 0 20px;

  .columns {
    .sidebar { grid-column: side; }
    .main    { grid-column: main; }

    @media (min-width: calc(1280em / 16)) {
      display: grid;
      gap: 0 var(--gutter);
      grid-template-columns: [side-start] repeat(8, 1fr) [side-end main-start] repeat(16, 1fr) [main-end];
    }

    @media (min-width: calc(1680em / 16)) {
      grid-template-columns: [side-start] repeat(8, 1fr) [side-end main-start] repeat(15, 1fr) [main-end] 1fr;
    }

    @media (min-width: calc(2560em / 16)) {
      grid-template-columns: [side-start] repeat(9, 1fr) [side-end main-start] repeat(11, 1fr) [main-end] repeat(4, 1fr);
    }
  }

  .section:where(.sidebar, .main) {
    margin-bottom: calc(100rem / 16);

    .section {
      position: relative;
      margin-bottom: calc(50rem / 16);
      padding-top: calc(5rem / 16);

      .heading { grid-column: head; }
      .text    { grid-column: body; }

      .border {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .section:where(.sidebar) {
    .inner {
      display: grid;
      gap: 0 var(--gutter);
      align-content: start;
    }

    .section:not(.credits) {
      display: grid;
      gap: 0 var(--gutter);
      grid-template-columns: [head-start] repeat(2, 1fr) [head-end body-start] repeat(4, 1fr) [body-end];
    }

    @media (min-width: calc(768em / 16)) and (max-width: calc(1279.98em / 16)) {
      .inner {
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: dense;
      }

      .section:not(.credits) {
        grid-column: 1;
      }
    }

    @media (min-width: calc(1280em / 16)) {
      --offset: calc(var(--header-height) + 150rem / 16);
      position: sticky;
      top: var(--offset);
      min-height: calc(100vh - var(--offset) - 20rem / 16);
      align-self: start;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      gap: 0 var(--gutter);

      .inner {
        grid-column: 1 / -1;
      }

      .pageListDesktop {
        grid-column: 1 / -1;
        margin-top: auto;
      }
    }

    @media (min-width: calc(1680em / 16)) {
      .inner {
        grid-column: 2 / -1;
      }

      .pageListDesktop {
        grid-column: 1 / -1;
      }
    }

    @media (min-width: calc(2560em / 16)) {
      grid-template-columns: repeat(9, 1fr);

      .inner {
        grid-column: 5 / -1;
      }

      .pageListDesktop {
        grid-column: 1 / -1;
      }
    }
  }

  .section:where(.main) {
    display: grid;
    align-self: start;
    gap: 0 var(--gutter);

    @media (min-width: calc(768em / 16)) {
      .section {
        display: grid;
        gap: 0 var(--gutter);
        grid-template-columns: [head-start] repeat(3, 1fr) [head-end body-start] repeat(9, 1fr) [body-end];
      }
    }

    @media (min-width: calc(1280em / 16)) {
      .section {
        grid-template-columns: [head-start] repeat(3, 1fr) [head-end body-start] repeat(13, 1fr) [body-end];
      }
    }
  }

  .heading {
    margin-bottom: calc(5rem / 16);
  }

  .credit {
    display: grid;
    gap: 0 var(--gutter);
    grid-template-columns: 1fr 1fr;
  }

  .pageListMobile {
    position: sticky;
    top: 10px;
    margin-bottom: calc(75rem / 16);

    @media (min-width: calc(1280em / 16)) {
      display: none;
    }
  }

  .pageListDesktop {
    display: none;

    @media (min-width: calc(1280em / 16)) {
      display: block;
    }
  }
}
