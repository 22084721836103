.button,
.primaryButton,
.pill {
  display: inline-block;
  padding: calc(7rem / 16) calc(8rem / 16) calc(8rem / 16);
  border-radius: 2px;
  background-color: var(--color-grey-light);
  color: var(--color-black);
  text-align: center;
  text-decoration: none;
  font-size: var(--font-xs);
  line-height: var(--lh-xs);
  white-space: nowrap;
}

.button,
.primaryButton {
  cursor: pointer;
  transition: border-radius 300ms ease-out 300ms, background-color 300ms ease-out 300ms, color 300ms ease-out 300ms;

  :where(.footer, .cartFooter) & {
    background-color: var(--color-white);
  }

  &:hover,
  &:active,
  &.active,
  :where(.radio input[type="radio"]:checked) + & {
    border-radius: 10px;
    background-color: var(--color-blue-1);
    color: var(--color-grey-light);
    transition: border-radius 300ms ease-out, background-color 300ms ease-out, color 300ms ease-out;
  }

  .radio input[type="radio"][disabled] + & {
    cursor: default;

    &:hover {
      border-radius: 2px;
      background-color: var(--color-grey-light);
      transition: none;
    }
  }
}

.primaryButton {
  background-color: var(--color-black);
  color: var(--color-white);

  &:hover {
    background-color: var(--color-blue-3);
  }

  &[disabled] {
    cursor: default;
    background-color: var(--color-grey-mid);
    color: var(--color-grey-light);
  }
}

@keyframes wiggle {
     0% { transform: rotate(0deg); }
    25% { transform: rotate(-3deg); }
    75% { transform: rotate(3deg); }
   100% { transform: rotate(0); }
}

.wiggle {
  animation: wiggle 200ms 10 ease-out;
}

.radio {
  label {
    cursor: pointer;
    width: 100%;
  }

  input[type="radio"] {
    display: block;
    appearance: none;
    background-color: transparent;
    margin: 0;
    width: 0;
    height: 0;

    &[disabled] + label {
      color: var(--color-grey-mid);
    }
  }
}

number-stepper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: max-content;
  grid-template-areas: "b1 in in b2";
  gap: var(--gutter);

  input {
    grid-area: in;
    background-color: var(--color-grey-mid);
    border-radius: 2px;
    appearance: none;
    border: 0;
    text-align: center;
    height: calc(30rem / 16);
    min-width: 0;
  }

  button {
    display: inline-flex;
    justify-content: center;
    width: calc(30rem / 16);
    height: calc(30rem / 16);
    border-radius: 50%;
    background-color: var(--color-grey-light);
    color: var(--color-black);
    text-align: center;
    text-decoration: none;
    font-size: var(--font-xs);
    line-height: calc(30rem / 16);
    white-space: nowrap;
    transition: transform 300ms ease-out;
    transform-origin: center;

    &:not([disabled]) {
      cursor: pointer;
    }

    &:nth-of-type(1) {
      grid-area: b1;
      justify-self: end;
      /* This is necessary to center the minus vertically: */
      line-height: calc(28rem / 16);

      &:not([disabled]):hover {
        transform: rotate(-180deg);
      }
    }

    &:nth-of-type(2) {
      grid-area: b2;
      justify-self: start;

      &:not([disabled]):hover {
        transform: rotate(180deg);
      }
    }
  }
}

:where(details).popover {
  position: relative;

  &[open] {
    .popoverOpener {
      display: none;
    }
  }

  &:not([open]) {
    .popoverCloser {
      display: none;
    }
  }

  > summary {
    list-style: none;
    display: inline;
    position: relative;
    cursor: pointer;
  }

  > summary::-webkit-details-marker {
    display: none;
  }

  > div {
    position: absolute;
    bottom: calc(100% + 5px);
    left: 0;
    z-index: 1;
  }
}

.skipper {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  border-radius: 2px;
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;
  background-color: transparent;
  padding: 8px 20px;
  background: var(--color-grey-light);
  color: var(--color-text);
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  top: 20px;
  left: 20px;
  z-index: 3;

  &:active,
  &:focus {
    clip: auto;
    overflow: visible;
    width: auto;
    height: auto;
  }

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  &:-moz-focusring {
    outline: 1px dotted;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}
