.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: calc(10px + 0.5 * var(--gutter));
  margin-right: calc(10px + 0.5 * var(--gutter));

  article {
    flex: 0 0 100%;
    margin-bottom: calc(75rem / 16);
    padding-left: calc(var(--gutter) / 2);
    padding-right: calc(var(--gutter) / 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(5rem / 16);

    a {
      cursor: pointer;
      display: block;
      width: 100%;
    }

    .preview {
      display: block;
      position: relative;
      width: 100%;
      aspect-ratio: 4 / 3;
      overflow: hidden;

      picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        background-color: var(--color-page-bg);

        &:first-child {
          z-index: 1;
        }
      }

      product-list[scrolling] & picture:first-child,
      &.flipped picture:first-child {
        opacity: 0;
      }

      @media (hover: hover) {
        &:hover picture:first-child {
          opacity: 0;
        }
      }
    }

    header {
      display: flex;
      gap: 0 var(--gutter);
      flex-direction: column;
      align-items: center;

      @media (min-width: calc(768em / 16)) {
        flex-direction: row;
        align-items: baseline;
      }

      h2, h3 {
        font-size: var(--font-md);
        line-height: var(--lh-md);
        font-weight: bold;
      }

      span {
        font-size: var(--font-sm);
        line-height: var(--lh-sm);
        font-weight: bold;
      }
    }
  }

  @media (min-width: calc(768em / 16)) {
    article {
      flex: 0 0 calc(100% / 2);
    }
  }

  @media (min-width: calc(1280em / 16)) {
    article {
      flex: 0 0 calc(100% / 3);
    }
  }

  @media (min-width: calc(1680em / 16)) {
    article {
      flex: 0 0 calc(100% / 4);
    }
  }

  @media (min-width: calc(2560em / 16)) {
    margin-left:  calc(0.5 * var(--gutter) + 100% * (4 / 24));
    margin-right: calc(0.5 * var(--gutter) + 100% * (4 / 24));
  }
}

@keyframes blink {
   0% { opacity: 1; }
  40% { opacity: 1; }
  50% { opacity: 0; }
  90% { opacity: 0; }
}

/* TODO: This needs the correct font. */
.soldOut {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    font-family: var(--font-mono);
    font-size: calc(46rem / 16);
    line-height: 1;
    text-transform: uppercase;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-name: blink;
    animation-timing-function: ease-out;
  }
}

product-zoomer {
  display: block;

  @media (min-width: calc(1280em / 16)) {
    cursor: zoom-in;

    &[zoomed] {
      cursor: zoom-out;
    }
  }
}

.productTitle {
  font-size: var(--font-md);
  font-weight: bold;
  line-height: var(--lh-md);

  @media (min-width: calc(2560em / 16)) {
    font-size: var(--font-lg);
    line-height: var(--lh-lg);
  }
}

.productPrice {
  font-size: var(--font-sm);
  font-weight: bold;
  line-height: var(--lh-sm);

  @media (min-width: calc(2560em / 16)) {
    font-size: var(--font-md);
    line-height: var(--lh-md);
  }
}

.productFacts {
  font-size: var(--font-xs);
  font-weight: bold;
  line-height: var(--lh-xs);

  @media (min-width: calc(2560em / 16)) {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: var(--gutter);
    grid-template-areas: ". x x x x .";

    > * {
      grid-area: x;
    }
  }
}

.product {
  padding: 0 20px;

  #main {
    display: grid;
    gap: var(--gutter);
    grid-template-columns: [views-start info-start images-start header-start] 1fr [views-end info-end images-end header-end];
    grid-template-rows: [views-start] max-content [views-end images-start] max-content [images-end header-start] max-content [header-end info-start] max-content [info-end];

    header {
      grid-area: header;
      text-align: center;

      > :nth-child(2) { margin-bottom: calc(15rem / 16); }
      > :nth-child(3) {
        margin-bottom: calc(75rem / 16);

        @media (min-width: calc(1280em / 16)) {
          margin-bottom: calc(30rem / 16);
        }
      }
    }

    .images {
      grid-area: images;

      @media (max-width: calc(1279.98em / 16)) {
        margin-left: calc(-1 * var(--gutter));
        margin-right: calc(-1 * var(--gutter));
      }

      @media (min-width: calc(1280em / 16)) {
        &[zoomed] {
          grid-column: 1 / -1;
        }
      }
    }

    .info {
      grid-area: info;
      display: grid;
      gap: var(--gutter);
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: max-content max-content;
      grid-template-areas:
        "intro intro intro intro intro intro"
        "rest rest rest rest rest .";

      /* Ignore pointer events on background to leave more space for <product-zoomer>. */
      pointer-events: none;
      > * { pointer-events: all; }

      .notes {
        display: flex;
        flex-direction: column;
        gap: calc(10rem / 16);
      }

      @media (max-width: calc(1279.98em / 16)) {
        a[href="#details"] {
          display: none;
        }
      }

      @media (min-width: calc(768em / 16)) and (max-width: calc(1279.98em / 16)) {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        .infoIntro {
          grid-column: 6 / -2;
          grid-row: 1;
        }

        .infoRest {
          grid-column: 1 / 5;
          grid-row: 1;
        }
      }
    }

    .infoIntro {
      grid-area: intro;
      text-align: center;
      font-weight: bold;
      font-size: var(--font-md);
      line-height: var(--lh-md);
      margin-bottom: calc(15rem / 16);

      @media (min-width: calc(2560em / 16)) {
        font-size: var(--font-lg);
        line-height: var(--lh-lg);
      }
    }

    .infoRest {
      grid-area: rest;
      display: flex;
      flex-direction: column;
      gap: var(--gutter);

      @media (max-width: calc(1279.98em / 16)) {
        .views {
          display: none;
        }
      }
    }

    @media (min-width: calc(1280em / 16)) {
      margin-left: calc(-1 * var(--gutter));
      margin-right: calc(-1 * var(--gutter));
      grid-template-columns: 0 [info-start] repeat(6, 1fr) [images-start] repeat(2, 1fr) [info-end] repeat(10, 1fr) [images-end header-start] repeat(6, 1fr) [header-end] 0;
      grid-template-rows: [info-start images-start header-start] max-content [info-end images-end header-end];

      header {
        position: sticky;
        top: calc(var(--callout-height) + 55px);
        top: 50vh;
        top: 50dvh;
        transform: translateY(-50%);
        align-self: start;
      }

      .info {
        position: sticky;
        top: calc(var(--header-height) + 2px);
        min-height: calc(100vh - var(--header-height) - 22px);
        align-self: start;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: 1fr max-content;
        grid-template-areas:
          "intro intro intro intro intro intro intro intro"
          "rest rest rest rest rest . . .";

        > :first-child {
          margin-bottom: auto;
        }
      }
    }

    @media (min-width: calc(1680em / 16)) {
      grid-template-columns: 0 [info-start] repeat(5, 1fr) [images-start] repeat(2, 1fr) [info-end] repeat(11, 1fr) [header-start] 1fr [images-end] repeat(5, 1fr) [header-end] 0;

      .info {
        grid-template-columns: repeat(7, 1fr);
        grid-template-areas:
          "intro intro intro intro intro intro intro"
          "rest rest rest rest . . .";
      }
    }

    @media (min-width: calc(2560em / 16)) {
      grid-template-columns: 0 [info-start] repeat(6, 1fr) [images-start] repeat(2, 1fr) [info-end] repeat(9, 1fr) [header-start] 1fr [images-end] repeat(5, 1fr) [header-end] 1fr 0;

      .info {
        grid-template-columns: repeat(8, 1fr);
        grid-template-areas:
          ". intro intro intro intro intro intro intro"
          "rest rest rest . . . . .";
      }
    }
  }

  #details {
    > div {
      display: grid;
      column-gap: var(--gutter);
    }

    dl > div {
      position: relative;
      align-self: start;
      display: grid;
      gap: calc(5rem / 16) var(--gutter);
      grid-template-columns: repeat(2, 1fr);
      padding-top: calc(5rem / 16);
      padding-bottom: calc(5rem / 16);

      .border {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    dl:nth-child(2) > div {
      grid-template-columns: 1fr;
    }

    dt {
      display: flex;
      align-items: baseline;

      button {
        margin-left: auto;

        @media (min-width: calc(1280em / 16)) {
          display: none;
        }
      }
    }

    dd {
      font-weight: bold;
    }

    definition-toggle {
      button {
        color: inherit;
        cursor: pointer;
        transition: transform 300ms ease-out;
        transform: rotate(45deg);
      }

      &[open] button {
        transform: rotate(0deg);
      }
    }

    @media (max-width: calc(1279.98em / 16)) {
      definition-toggle {
        & + dd {
          height: 0;
          overflow: hidden;
          transition: height 300ms ease-out;
        }

        &[open] + dd {
          height: max-content;
        }
      }
    }

    @media (min-width: calc(768em / 16)) {
      > div {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media (min-width: calc(1280em / 16)) {
      > div {
        grid-template-columns: 1fr;
      }

      dl {
        display: grid;
        gap: 0 var(--gutter);
      }

      dl:nth-child(1) {
        grid-template-columns: repeat(4, 1fr);
      }

      dl:nth-child(2) {
        grid-template-columns: repeat(2, 1fr);

        > div {
          grid-template-columns: calc((100% - 11 * var(--gutter)) / 12 * 3 + 2 * var(--gutter)) 1fr;
        }
      }
    }

    @media (min-width: calc(2560em / 16)) {
      margin-left:  calc(0.5 * var(--gutter) + 100% * 4 / 24);
      margin-right: calc(0.5 * var(--gutter) + 100% * 4 / 24);
    }
  }
}

.product {
  .controlsMobile {
    position: sticky;
    bottom: var(--navbar-height);
    bottom: calc(var(--navbar-height) + env(safe-area-inset-bottom, 0));
    display: grid;
    grid-template-columns: repeat(6, 1fr);;
    grid-template-rows: max-content max-content;
    grid-template-areas:
      "var var var stp stp stp"
      "btn btn btn btn btn btn";
    gap: 5px var(--gutter);
    align-items: end;

    details {
      grid-area: var;
      width: 100%;

      summary, > div {
        width: 100%;
      }
    }

    .variants {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    number-stepper {
      grid-area: stp;

      @media (max-width: calc(767.98em / 16)) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: "b1 in b2";
      }
    }

    .primaryButton {
      grid-area: btn;
    }

    @media (min-width: calc(768em / 16)) {
      grid-template-columns: repeat(12, 1fr);;
      grid-template-rows: max-content;
      grid-template-areas:
        "var var var var stp stp stp stp btn btn btn btn";
    }

    @media (min-width: calc(1280em / 16)) {
      display: none;
    }
  }

  .controlsDesktop {
    display: none;
    grid-template-columns: [outer-start] 1fr [inner-start] repeat(4, 1fr) [inner-end] 1fr [outer-end];
    gap: 5px var(--gutter);

    .variants {
      grid-column: outer;
      display: flex;
      flex-wrap: wrap;
      gap: 5px var(--gutter);
      margin-bottom: calc(25rem / 16);
      justify-content: center;

      > * {
        flex: 0 0 calc(100% / 2 - 0.5 * var(--gutter));
      }

      @media (min-width: calc(1680em / 16)) {
        > * {
          flex: 0 0 calc(100% / 3 - 0.75 * var(--gutter));
        }
      }
    }

    .selectedPrice,
    number-stepper,
    .primaryButton,
    .soldOut {
      grid-column: inner;
      align-self: stretch;
    }

    .soldOut {
      margin-top: calc(25rem / 16);
    }

    @media (min-width: calc(1280em / 16)) {
      display: grid;
    }
  }
}

.views {
  display: flex;
  gap: var(--gutter);

  li a::before {
    padding-right: 5px;
  }

  li:nth-child(1) a::before { content: "①"; }
  li:nth-child(2) a::before { content: "②"; }
  li:nth-child(3) a::before { content: "③"; }

  &.mobile {
    padding-bottom: calc(5rem / 16);

    @media (min-width: calc(1280em / 16)) {
      display: none;
    }
  }

  &.mobile:first-child {
    grid-area: views;
    position: sticky;
    top: 5px;
  }

  /* Invisible placeholder */
  &.mobile:last-child {
    visibility: hidden;
  }
}
