.invisible {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.arrow {
  float: left;
  margin-right: calc(5rem / 16);
  font-weight: normal;
}

.xs, .xsb { font-size: var(--font-xs); line-height: var(--lh-xs); }
.sm, .smb { font-size: var(--font-sm); line-height: var(--lh-sm); }
.md, .mdb { font-size: var(--font-md); line-height: var(--lh-md); }
.lg, .lgb { font-size: var(--font-lg); line-height: var(--lh-lg); }
.xl, .xlb { font-size: var(--font-xl); line-height: var(--lh-xl); }

.xsb, .smb, .mdb, .lgb, .xlb {
  font-weight: bold;
}

.b { font-weight: bold; }
.c { text-align: center; }

.text > * + * {
  margin-top: calc(5rem / 16);
}

.section {
  margin-bottom: calc(150rem / 16);
}

.sectionHeader {
  margin-bottom: calc(15rem / 16);

  h1, h2, h3, h4, h5, h6 {
    text-align: center;
  }

  a {
    display: none;
  }

  @media (min-width: calc(768em / 16)) {
    display: grid;
    grid-template-columns: [aside-start] 1fr [aside-end title-start] max-content [title-end] 1fr;
    gap: var(--gutter);
    align-items: baseline;

    h1, h2, h3, h4, h5, h6 {
      grid-column: title;
    }

    a {
      grid-column: aside;
      display: unset;
    }
  }
}

.sectionFooter {
  display: grid;
  gap: var(--gutter);
  grid-template-columns: 1fr [btn-start] repeat(4, 1fr) [btn-end] 1fr;
  margin-left: 20px;
  margin-right: 20px;

  .button {
    grid-column: btn;
  }

  @media (min-width: calc(768em / 16)) {
    grid-template-columns: repeat(3, 1fr) [btn-start] repeat(6, 1fr) [btn-end] repeat(3, 1fr);
  }

  @media (min-width: calc(1280em / 16)) {
    grid-template-columns: repeat(9, 1fr) [btn-start] repeat(6, 1fr) [btn-end] repeat(9, 1fr);
  }

  @media (min-width: calc(2560em / 16)) {
    grid-template-columns: repeat(10, 1fr) [btn-start] repeat(4, 1fr) [btn-end] repeat(10, 1fr);
  }
}
