.questions {
  margin-left: 20px;
  margin-right: 20px;

  @media (min-width: calc(1280em / 16)) {
    display: grid;
    gap: var(--gutter);
    grid-template-columns: repeat(1, 1fr) [QA-start] repeat(22, 1fr) [QA-end] repeat(1, 1fr);

    > * { grid-column: QA; }
  }

  @media (min-width: calc(1680em / 16)) {
    grid-template-columns: repeat(3, 1fr) [QA-start] repeat(18, 1fr) [QA-end] repeat(3, 1fr);
  }

  @media (min-width: calc(2560em / 16)) {
    grid-template-columns: repeat(5, 1fr) [QA-start] repeat(14, 1fr) [QA-end] repeat(5, 1fr);
  }
}

.question {
  position: relative;
  display: grid;
  grid-template-columns: [Q-start A-start] 1fr [Q-end A-end];
  gap: 0 var(--gutter);
  margin-bottom: calc(75rem / 16);

  .border {
    position: absolute;
    top: 0;
    left: 0;
  }

  .Q {
    grid-column: Q;
    margin-top: calc(10rem / 16);
    margin-bottom: calc(10rem / 16);
    font-size: var(--font-xs);
    line-height: var(--lh-xs);
    font-weight: normal;
  }

  .A {
    grid-column: A;
    margin-top: calc(5rem / 16);
    font-size: var(--font-sm);
    line-height: var(--lh-sm);
    font-weight: bold;
    text-align: center;
  }

  @media (min-width: calc(768em / 16)) {
    grid-template-columns: [Q-start] repeat(3, 1fr) [Q-end A-start] repeat(9, 1fr) [A-end];
  }

  @media (min-width: calc(1280em / 16)) {
    grid-template-columns: [Q-start] repeat(6, 1fr) [Q-end] repeat(2, 1fr) [A-start] repeat(12, 1fr) [A-end] repeat(2, 1fr);
  }

  @media (min-width: calc(1680em / 16)) {
    grid-template-columns: [Q-start] repeat(5, 1fr) [Q-end] 1fr [A-start] repeat(11, 1fr) [A-end] 1fr;
  }

  @media (min-width: calc(2560em / 16)) {
    grid-template-columns: [Q-start] repeat(5, 1fr) [Q-end] 1fr [A-start] repeat(7, 1fr) [A-end] 1fr;
  }
}
