.articles {
  padding-left: 20px;
  padding-right: 20px;

  article {
    position: relative;
    padding-top: calc(5rem / 16);
    margin-bottom: calc(75rem / 16);

    .border {
      position: absolute;
      top: 0;
      left: 0;
    }

    p {
      max-width: calc(100% * 5 / 6);
      margin-bottom: calc(25rem / 16);

      a {
        white-space: nowrap;
      }
    }

    picture, lazy-video {
      display: block;
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  @media (min-width: calc(768em / 16)) {
    article {
      display: grid;
      grid-template-columns: [txt-start] repeat(4, 1fr) [txt-end img-start] repeat(8, 1fr) [img-end];
      gap: var(--gutter);

      p {
        grid-column: txt;
        max-width: unset;
      }

      picture, lazy-video {
        grid-column: img;
        margin: 0;
      }
    }
  }

  @media (min-width: calc(1280em / 16)) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--gutter);
  }

  @media (min-width: calc(1680em / 16)) {
    grid-template-columns: 1fr [A-start] repeat(11, 1fr) [A-end B-start] repeat(11, 1fr) [B-end] 1fr;

    article {
      grid-template-columns: [txt-start] repeat(4, 1fr) [txt-end img-start] repeat(7, 1fr) [img-end];

      &:nth-child(2n + 1) { grid-column: A; }
      &:nth-child(2n + 2) { grid-column: B; }
    }
  }

  @media (min-width: calc(2560em / 16)) {
    grid-template-columns: repeat(3, 1fr) [A-start] repeat(6, 1fr) [A-end B-start] repeat(6, 1fr) [B-end C-start] repeat(6, 1fr) [C-end] repeat(3, 1fr);

    article {
      grid-template-columns: [txt-start] repeat(2, 1fr) [txt-end img-start] repeat(4, 1fr) [img-end];

      &:nth-child(3n + 1) { grid-column: A; }
      &:nth-child(3n + 2) { grid-column: B; }
      &:nth-child(3n + 3) { grid-column: C; }
    }
  }
}

.media {
  display: grid;
  grid-template-columns: [img-start] 0 [cap-start] repeat(5, 1fr) [cap-end] 1fr 0 [img-end];
  column-gap: var(--gutter);
  row-gap: calc(5rem / 16);
  margin-bottom: calc(75rem / 16);
  padding-left: 10px;
  padding-right: 10px;

  > div {
    grid-column: img;
    display: flex;
    flex-direction: column;
    gap: var(--gutter);
  }

  figcaption {
    grid-column: cap;
  }

  img, video {
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: calc(768em / 16)) {
    grid-template-columns: [img-start] 0 [cap-start] repeat(11, 1fr) [cap-end] 1fr 0 [img-end];

    &.mediumLeft {
      grid-template-columns: 0 repeat(1, 1fr) [img-start cap-start] repeat(8, 1fr) [img-end cap-end] repeat(3, 1fr) 0;
    }
    &.mediumCenter {
      grid-template-columns: 0 repeat(2, 1fr) [img-start cap-start] repeat(8, 1fr) [img-end cap-end] repeat(2, 1fr) 0;
    }
    &.mediumRight {
      grid-template-columns: 0 repeat(3, 1fr) [img-start cap-start] repeat(8, 1fr) [img-end cap-end] repeat(1, 1fr) 0;
    }
    &.smallLeft {
      grid-template-columns: 0 repeat(1, 1fr) [img-start cap-start] repeat(6, 1fr) [img-end cap-end] repeat(5, 1fr) 0;
    }
    &.smallRight {
      grid-template-columns: 0 repeat(5, 1fr) [img-start cap-start] repeat(6, 1fr) [img-end cap-end] repeat(1, 1fr) 0;
    }

    > div {
      flex-direction: row;
      align-items: stretch;
    }
  }

  @media (min-width: calc(1280em / 16)) {
    grid-template-columns: [img-start] 0 [cap-start] repeat(18, 1fr) [cap-end] repeat(6, 1fr) 0 [img-end];

    &.mediumRight {
      grid-template-columns: 0 repeat(1, 1fr) [img-start cap-start] repeat(14, 1fr) [img-end cap-end] repeat(9, 1fr) 0;
    }
    &.mediumCenter {
      grid-template-columns: 0 repeat(6, 1fr) [img-start cap-start] repeat(12, 1fr) [img-end cap-end] repeat(6, 1fr) 0;
    }
    &.mediumRight {
      grid-template-columns: 0 repeat(9, 1fr) [img-start cap-start] repeat(14, 1fr) [img-end cap-end] repeat(1, 1fr) 0;
    }
    &.smallLeft {
      grid-template-columns: 0 repeat(4, 1fr) [img-start cap-start] repeat(8, 1fr) [img-end cap-end] repeat(12, 1fr) 0;
    }
    &.smallRight {
      grid-template-columns: 0 repeat(12, 1fr) [img-start cap-start] repeat(8, 1fr) [img-end cap-end] repeat(4, 1fr) 0;
    }
  }

  @media (min-width: calc(2560em / 16)) {
    grid-template-columns: [img-start] 0 [cap-start] repeat(14, 1fr) [cap-end] repeat(10, 1fr) 0 [img-end];

    &.mediumLeft {
      grid-template-columns: 0 repeat(3, 1fr) [img-start cap-start] repeat(11, 1fr) [img-end cap-end] repeat(10, 1fr) 0;
    }
    &.mediumRight {
      grid-template-columns: 0 repeat(10, 1fr) [img-start cap-start] repeat(11, 1fr) [img-end cap-end] repeat(3, 1fr) 0;
    }
    &.smallLeft {
      grid-template-columns: 0 repeat(3, 1fr) [img-start cap-start] repeat(7, 1fr) [img-end cap-end] repeat(14, 1fr) 0;
    }
    &.smallRight {
      grid-template-columns: 0 repeat(14, 1fr) [img-start cap-start] repeat(7, 1fr) [img-end cap-end] repeat(3, 1fr) 0;
    }
  }
}
