splash-screen {
  display: none;
  z-index: 6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-page-bg);
  transition: opacity 500ms ease-out;
  gap: var(--gutter);
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: max-content 1fr max-content;
  grid-template-areas:
    ". . . call call call"
    ". . . . . ."
    "tag tag tag tag tag tag";
  padding: 5px 20px;
  overflow: hidden;

  &.fade {
    opacity: 0;
  }

  html.showSplash & {
    display: grid;
  }

  .callout {
    grid-area: call;
    transform: rotate(-4deg);
  }

  .tagline {
    grid-area: tag;
    font-size: var(--font-md);
    line-height: var(--lh-md);
    font-weight: bold;
    text-align: center;
  }

  @media (min-width: calc(768em / 16)) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
      ". . . . call call call call . . . ."
      ". . . . . . . . . . . ."
      "tag tag tag tag tag tag tag tag tag tag tag tag";

    .callout {
      transform: rotate(2deg);
    }
  }

  @media (min-width: calc(1280em / 16)) {
    grid-template-columns: repeat(24, 1fr);
    grid-template-areas:
      ". . . . . . . . . . . . . call call call call . . . . . . ."
      ". . . . . . . . . . . . . . . . . . . . . . . ."
      "tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag";
  }

  @media (min-width: calc(1680em / 16)) {
    grid-template-areas:
      ". . . . . . . . . . . . . . . . call call call . . . . ."
      ". . . . . . . . . . . . . . . . . . . . . . . ."
      "tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag";
  }

  @media (min-width: calc(2560em / 16)) {
    grid-template-areas:
      ". . . . . . . . . . . . . . . . . . . call call . . ."
      ". . . . . . . . . . . . . . . . . . . . . . . ."
      "tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag tag";
  }
}

.siteIntro {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 0;

  .images {
    display: flex;
    flex-direction: column;
    gap: var(--gutter);
    width: 100%;

    > * {
      min-width: 0;

      /* Reverse the order of intro images on mobile so that the callout remains legible. */
      @media (max-width: calc(1279.98em / 16)) {
        &:first-child {
          order: 2;
        }
      }
    }

    img, video {
      aspect-ratio: 1;
      object-fit: cover;
    }
  }

  .logoWrapper {
    padding-top: calc(10rem / 16);

    &::after {
      content: "";
      display: block;
      height: calc(150rem / 16);
    }
  }

  .logo {
    position: sticky;
    top: 0;
  }

  .calloutWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 5px 20px;
    display: grid;
    gap: var(--gutter);
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: max-content 1fr max-content;
    grid-template-areas:
      ". . . call call call";
    overflow: hidden;

    .callout {
      grid-area: call;
      transform: rotate(-4deg);
    }

    @media (min-width: calc(768em / 16)) {
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas:
        ". . . . call call call call . . . .";
  
      .callout {
        transform: rotate(2deg);
      }
    }
  }

  @media (min-width: calc(1280em / 16)) {
    .images {
      flex-direction: row;

      picture, video, lazy-video {
        flex: 1 1 0;
      }
    }

    .logoWrapper, .calloutWrapper {
      display: none;
    }
  }
}
