.cart {
  flex: 1;
  display: flex;
  flex-direction: column;

  .heading {
    margin-bottom: 10px;
  }

  .border {
    margin-top: 5px;
  }
}

.cartTop {
  padding: 0 20px;
  margin-bottom: calc(75rem / 16);

  @media (min-width: calc(1280em / 16)) {
    display: none;
  }
}

.cart:not(.cartEmpty) .cartEmptyMessage {
  display: none;
}

.cart.cartEmpty .cartJumpLink {
  display: none;
}

.cartLines {
  flex: 1;
  padding: 0 20px;
  margin-bottom: calc(75rem / 16);
}

.cartHeader, .cartLine {
  display: grid;
  gap: 10px var(--gutter);
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, max-content) 20px max-content 20px max-content 20px max-content;
  grid-template-areas:
    "img img img img img img"
    "nap nap nap nap nap nap"
    ". . . . . ."
    "siz siz siz uni uni uni"
    ". . . . . ."
    "amt amt amt amt amt amt"
    ". . . . . ."
    ". . . sub sub sub";

  .image {
    grid-area: img;
  }

  .nameAndPrice {
    grid-area: nap;
  }

  .size {
    grid-area: siz;

    .pill {
      display: block;

      > span {
        display: block;
        width: calc(100% + 8rem / 16);
        overflow: hidden;
      }
    }
  }

  .unitPrice {
    grid-area: uni;
  }

  .amount {
    grid-area: amt;
  }

  .subtotal {
    grid-area: sub;
  }

  /* Fake alignment by baseline because form controls are higher than text. */
  .unitPrice, .subtotal {
    .heading + * {
      margin-top: 15px;
    }
  }

  @media (min-width: calc(768em / 16)) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: max-content max-content;
    grid-template-areas:
      "img img img img img img nap nap nap nap nap nap"
      "siz siz uni uni amt amt amt amt amt amt sub sub";
  }

  @media (min-width: calc(1280em / 16)) {
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: max-content;
    grid-template-areas:
      "img img img img img img nap nap nap nap nap nap siz siz uni uni amt amt amt amt amt amt sub sub";
  }

  @media (min-width: calc(2560em / 16)) {
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: max-content;
    grid-template-areas:
      "img img img img img img nap nap nap nap nap nap nap siz siz uni uni amt amt amt amt amt sub sub";
  }
}

.cartHeader {
  display: none;

  @media (min-width: calc(1280em / 16)) {
    position: sticky;
    top: 0;
    margin-top: calc(-1 * var(--header-height));
    padding-top: calc(var(--header-height) + 150rem / 16);
    display: grid;
    background-color: var(--color-white);

    .heading {
      margin-bottom: 0;
    }
  }
}

.cartLine {
  margin-bottom: calc(75rem / 16);

  .amountControls {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: max-content;
    grid-template-areas: "amt amt amt amt del del";
    align-items: baseline;
    gap: 5px var(--gutter);

    > :nth-child(1) {
      grid-area: amt;
    }

    > :nth-child(2) {
      grid-area: del;
    }
  }

  @media (min-width: calc(1280em / 16)) {
    li:first-child & .border {
      display: none;
    }

    .heading {
      > span {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
      }
    }
  }
}

.cartFooter {
  background-color: var(--color-grey-light);
  padding: 10px 20px;
  display: grid;
  gap: 10px var(--gutter);
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: max-content max-content 75px max-content max-content 30px max-content max-content;
  grid-template-areas:
    "lgl lgl lgl lgl lgl lgl"
    "prc prc prc prc prc prc"
    ". . . . . ."
    "ext ext ext ext ext ext"
    ". . . tot tot tot"
    ". . . . . ."
    "log log log log log log"
    "btn btn btn btn btn btn";

  .legalInfo {
    grid-area: lgl;
  }

  .priceInfo {
    grid-area: prc;
  }

  .extraInfo {
    grid-area: ext;
  }

  .total {
    grid-area: tot;

    .heading {
      text-transform: uppercase;
    }
  }

  .logos {
    grid-area: log;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    > * {
      width: unset;
    }
  }

  .buttons {
    grid-area: btn;
    display: flex;
    flex-direction: column;
    gap: 5px var(--gutter);
    align-items: stretch;
  }

  @media (min-width: calc(768em / 16)) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: max-content 75px max-content max-content;
    grid-template-areas:
      "lgl lgl lgl lgl lgl lgl lgl lgl prc prc prc prc"
      ". . . . . . . . . . . ."
      ". . . . ext ext ext ext ext . tot tot"
      "log log log log btn btn btn btn btn btn btn btn";

    .buttons {
      flex-direction: row;

      > * {
        flex: 1;
      }
    }
  }

  @media (min-width: calc(1280em / 16)) {
    position: sticky;
    bottom: 0;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: max-content max-content;
    grid-template-areas:
      "lgl lgl lgl lgl lgl lgl lgl lgl lgl lgl . prc prc prc prc prc ext ext ext ext ext ext tot tot"
      ". . . . . . . . . . . log log log log log btn btn btn btn btn btn btn btn";
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  @media (min-width: calc(1680em / 16)) {
    grid-template-areas:
      "lgl lgl lgl lgl lgl lgl lgl lgl . . . prc prc prc prc prc ext ext ext ext ext ext tot tot"
      ". . . . . . . . . . . log log log log log btn btn btn btn btn btn btn btn";
  }

  @media (min-width: calc(2560em / 16)) {
    grid-template-areas:
      "lgl lgl lgl lgl lgl lgl . . . . . . . . . prc prc prc ext ext ext ext tot tot"
      ". . . . . . . . . . . . . . . log log log btn btn btn btn btn btn";
  }
}

cart-quantity {
  span::before { content: "(" }
  span::after  { content: ")" }
  
  &[quantity="0"], &[quantity="1"], &[quantity="2"], &[quantity="3"], &[quantity="4"], &[quantity="5"], &[quantity="6"], &[quantity="7"], &[quantity="8"], &[quantity="9"], &[quantity="10"] {
    span {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }
}

cart-quantity[quantity="0"]::after { content: "⓪" }
cart-quantity[quantity="1"]::after { content: "①" }
cart-quantity[quantity="2"]::after { content: "②" }
cart-quantity[quantity="3"]::after { content: "③" }
cart-quantity[quantity="4"]::after { content: "④" }
cart-quantity[quantity="5"]::after { content: "⑤" }
cart-quantity[quantity="6"]::after { content: "⑥" }
cart-quantity[quantity="7"]::after { content: "⑦" }
cart-quantity[quantity="8"]::after { content: "⑧" }
cart-quantity[quantity="9"]::after { content: "⑨" }
cart-quantity[quantity="10"]::after { content: "⑩" }
