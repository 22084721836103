.headerMobile,
.headerDesktop,
.headerCallout,
.headerDialog {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 5px var(--gutter);
  padding: 5px 20px;

  @media (min-width: calc(768em / 16)) {
    grid-template-columns: repeat(12, 1fr);
  }

  @media (min-width: calc(1280em / 16)) {
    grid-template-columns: repeat(24, 1fr);
  }

  .logo {
    grid-area: logo;
  }

  .menu {
    grid-area: menu;
  }

  .callout, .calloutDelivery {
    grid-area: call;
  }

  .calloutDelivery {
    height: 0;
    overflow: visible;
  }

  .calloutOverlay {
    grid-area: call2;

    .callout {
      transform: rotate(6deg);

      .title {
        max-width: 9ch;
      }
    }
  }

  .headerToggle {
    grid-area: togl;
  }

  .cartOpener {
    grid-area: cart;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 5px 10px;

  li > * {
    width: 100%;
  }

  .button {
    text-transform: uppercase;
    letter-spacing: calc(0.98rem / 16);
  }

  @media screen and (min-width: calc(1280em / 16)) {
    flex-direction: row;

    li {
      flex: 1 1 0;
    }
  }
}

.headerMobile {
  z-index: 5;
  order: 1;
  position: sticky;
  bottom: 0;
  bottom: env(safe-area-inset-bottom, 0);
  grid-template-areas:
    "togl togl togl togl cart cart";

  @media (min-width: calc(768em / 16)) {
    grid-template-areas:
      "togl togl togl togl togl togl togl togl togl togl cart cart";
  }

  @media (min-width: calc(1280em / 16)) {
    display: none;
  }

  .headerToggle {
    text-transform: uppercase;
  }
}

.headerDesktop,
.headerCallout {
  display: none;

  @media (min-width: calc(1280em / 16)) {
    display: grid;
    grid-template-areas:
      "logo logo logo logo logo . menu menu menu menu menu menu menu menu menu menu menu menu call call call call cart cart";
    align-items: start;
  }

  @media (min-width: calc(1680em / 16)) {
    grid-template-areas:
      "logo logo logo logo . . menu menu menu menu menu menu menu menu menu menu menu menu . call call call cart cart";
  }

  @media (min-width: calc(2560em / 16)) {
    grid-template-areas:
      "logo logo logo . . . menu menu menu menu menu menu menu menu menu menu menu menu . . . call call cart";
  }
}

.headerDesktop {
  z-index: 3;
  position: sticky;
  top: 0;
  grid-template-rows: auto;
  padding-top: 0;
  padding-bottom: 0;

  header,
  nav {
    display: contents;
  }

  .logo {
    justify-self: start;
  }

  .menu,
  .cartOpener {
    margin-top: 10px;
  }

  @media print {
    /* Hide everything but the logo when printing. */
    /* TODO: Fix print styles. They are currently broken. */
    .menu,
    .callout,
    .cartOpener {
      display: none !important;
    }
  }
}

.headerCallout {
  z-index: 7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;

  .callout {
    pointer-events: all;
  }

  @media (min-width: calc(768em / 16)) and (max-width: calc(1279.98em / 16)) {
    .homeTemplate & {
      position: absolute;
      display: grid;
      grid-template-areas:
        ". . . . . . . . call call call .";
      align-items: start;
    }
  }
}

header-dialog {
  @media (min-width: calc(1280em / 16)) {
    display: none;
  }
}

.headerDialog {
  width: 100%;
  height: 100%;
  grid-template-rows: max-content 1fr max-content max-content max-content max-content;
  grid-template-areas:
    ". . . call call call"
    ". . . . . ."
    "call2 call2 call2 . . ."
    "logo logo logo logo logo logo"
    "menu menu menu menu menu menu"
    "togl togl togl togl cart cart";
  background-color: var(--color-page-bg);

  @media (min-width: calc(768em / 16)) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: max-content 1fr max-content max-content max-content max-content;
    grid-template-areas:
      ". . . . . . . . call call call ."
      ". . . . . . . . . . . ."
      "call2 call2 call2 call2 . . . . . . . ."
      "logo logo logo logo logo logo logo logo logo logo . ."
      "menu menu menu menu menu menu menu menu menu menu . ."
      "togl togl togl togl togl togl togl togl togl togl cart cart";
  }

  nav {
    display: contents;
  }
}

.page {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--color-page-bg);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

main {
  flex: 1;
  padding-top: 10px;

  :is(.productTemplate, .cartTemplate) & {
    padding-top: 5px;
  }

  .cartTemplate & {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: calc(1280em / 16)) {
    padding-top: calc(150rem / 16);

    :is(.productTemplate, .cartTemplate) & {
      padding-top: 0;
    }

    .cartTemplate & {
      margin-bottom: 1px;
    }
  }
}

.footer {
  position: relative;
  padding: 15px 20px 15px;
  padding: 15px 20px calc(15px + env(safe-area-inset-bottom, 0));

  .callout {
    /* TODO: Position footer callout better. */
    position: absolute;
    top: -32px;
    left: 80px;
    transform: rotate(2deg);

    @media (max-width: calc(1279.98em / 16)) {
      display: none;
    }
  }

  footer {
    display: grid;
    row-gap: calc(15rem / 16);

    .linkBlock {
      display: grid;
      gap: calc(5rem / 16) var(--gutter);
      grid-template-columns: [txt-start] 1fr [btn-start] repeat(4, 1fr) [btn-end] 1fr [txt-end];
      text-align: center;

      @media (min-width: calc(768em / 16)) {
        grid-template-columns: [txt-start] repeat(4, 1fr) [btn-start] repeat(4, 1fr) [btn-end] repeat(4, 1fr) [txt-end];
      }

      @media (min-width: calc(1280em / 16)) {
        grid-template-columns: [txt-start] repeat(10, 1fr) [btn-start] repeat(4, 1fr) [btn-end] repeat(10, 1fr) [txt-end];
      }

      p {
        grid-column: txt;
        font-size: var(--font-md);
        line-height: var(--lh-md);
        font-weight: bold;
      }

      a {
        grid-column: btn;
      }
    }

    .smallprintBlock {
      display: grid;
      gap: var(--gutter);
      grid-template-columns: [A-start C-start] repeat(3, 1fr) [A-end B-start] repeat(3, 1fr) [B-end C-end];
      margin-top: calc(75rem / 16);
      line-height: calc(15 / 14);

      @media (min-width: calc(768em / 16)) {
        grid-template-columns: [A-start] repeat(3, 1fr) [A-end B-start] repeat(5, 1fr) [B-end C-start] repeat(4, 1fr) [C-end];
      }

      @media (min-width: calc(1280em / 16)) {
        grid-template-columns: [A-start] repeat(4, 1fr) [A-end B-start] repeat(6, 1fr) [B-end] repeat(10, 1fr) [C-start] repeat(4, 1fr) [C-end];
      }

      @media (min-width: calc(1680em / 16)) {
        grid-template-columns: [A-start] repeat(3, 1fr) [A-end B-start] repeat(4, 1fr) [B-end] repeat(14, 1fr) [C-start] repeat(3, 1fr) [C-end];
      }

      @media (min-width: calc(2560em / 16)) {
        grid-template-columns: [A-start] repeat(2, 1fr) [A-end B-start] repeat(4, 1fr) [B-end] repeat(16, 1fr) [C-start] repeat(2, 1fr) [C-end];
      }

      > div {
        &:nth-child(3n + 1) { grid-column: A; }
        &:nth-child(3n + 2) { grid-column: B; }
        &:nth-child(3n + 3) { grid-column: C; }
      }
    }
  }
}
