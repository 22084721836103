@font-face {
  font-family: "ABCWalterNeue";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/ABCWalterNeue-Normal.woff2") format("woff2"),
    url("../fonts/ABCWalterNeue-Normal.woff") format("woff"),
    url("../fonts/ABCWalterNeue-Normal.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "ABCWalterNeue";
  font-style: normal;
  font-weight: 700;
  src:  url("../fonts/ABCWalterNeue-Outline-40.woff2") format("woff2"),
    url("../fonts/ABCWalterNeue-Outline-40.woff") format("woff"),
    url("../fonts/ABCWalterNeue-Outline-40.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "ABCDiatypeSemi-MonoCompressed";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/ABCDiatypeSemi-MonoCompressed-Regular.woff2") format("woff2"),
    url("../fonts/ABCDiatypeSemi-MonoCompressed-Regular.woff") format("woff"),
    url("../fonts/ABCDiatypeSemi-MonoCompressed-Regular.otf") format("opentype");
  font-display: swap;
}

:root {
  --font-sans: "ABCWalterNeue", sans-serif;
  --font-mono: "ABCDiatypeSemi-MonoCompressed", sans-serif;

  --font-xs: calc(14rem / 16);
  --font-sm: calc(19rem / 16);
  --font-md: calc(25rem / 16);
  --font-lg: calc(35rem / 16);
  --font-xl: calc(55rem / 16);

  --lh-xs: calc(15 / 14);
  --lh-sm: 1;
  --lh-md: 1;
  --lh-lg: calc(32 / 35);
  --lh-xl: 1;

  --gutter: 10px;

  --color-black: #070707;
  --color-white: #f8f8f8;
  --color-blue: #00b4ff;
  --color-grey-light: #eeeeee;
  --color-grey-mid: #a2a2a2;
  --color-blue-1: #00b4ff;
  --color-blue-2: #007df0;
  --color-blue-3: #001ef5;

  --color-text: var(--color-black);
  --color-text-muted: var(--color-grey-mid);
  --color-page-bg: var(--color-white);
  --color-footer-bg: var(--color-grey-light);

  --header-height: calc(
    var(--font-xl) +
    var(--font-md) +
    calc(-1 * 5.5rem / 16)
  );

  --navbar-height: calc(
    var(--font-xs) + 26px
  );

  --callout-height: calc(
    var(--font-lg) * 2 +
    var(--font-xl) +
    var(--font-md) + 10px
  );
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings:
    "dlig" 1,
    "kern" 1;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  line-sizing: normal;
  background-color: var(--color-footer-bg);
  color: var(--color-text);
  scroll-behavior: smooth;
  interpolate-size: allow-keywords;
}

body {
  min-height: 100vh;
  min-height: 100dvh;
  font-family: var(--font-sans);
  font-size: var(--font-xs);
  line-height: var(--lh-xs);

  &:has(dialog[open]),
  html.showSplash & {
    overflow: hidden;
  }
}

/* Page transitions */
body {
  display: grid;
  grid-template-areas: "root";

  > #root {
    grid-area: root;
    display: flex;
    flex-direction: column;
  }
}
.is-changing .transition-fade {
  transition: opacity 500ms ease-out;
}
.transition-fade.is-previous-container {
  opacity: 0
}
.transition-fade.is-next-container {
  opacity: 0;
}

[hidden] {
  display: none;
}

::selection {
  background: var(--color-blue-2);
  color: var(--color-white);
}

pre {
  white-space: pre-wrap;
}

hr {
  border-style: solid;
  border-width: 1px 0 0;
  color: inherit;
  height: 0;
  overflow: visible;
}

img, svg, video, canvas, audio, iframe, embed, object {
  display: block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

img, video, lazy-video {
  width: 100%;
}

img, video {
  background-color: var(--color-grey-light);
  border-radius: 2px;
}

h1, h2, h3, h4, h5, h6, input, button, textarea, select {
  font: inherit;
}

button {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  border-radius: 0;
  appearance: none;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

h1, h2, h3, h4, h5, h6 {
  text-wrap: balance;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

:where(a) {
  color: currentColor;
  text-decoration: none;
  text-underline-offset: .2ex
}

a:not([class]),
.link {
  cursor: pointer;
  color: var(--color-grey-mid);
  transition: color 200ms ease-out;

  &:hover {
    color: var(--color-blue-2);
  }

  &.active {
    color: var(--color-black);
  }
}

:target {
  scroll-margin-block: 5ex;

  @media (min-width: calc(1280em / 16)) {
    scroll-margin-block: calc(var(--callout-height));
  }
}

dialog {
  border: 0;
  width: 100%;
  width: 100dvw;
  height: 100%;
  height: 100dvh;
  max-width: unset;
  max-height: unset;
}

dialog, dialog::backdrop {
  transition:
    display 300ms ease-out allow-discrete,
    overlay 300ms ease-out allow-discrete;

  animation: close 300ms forwards;
}
dialog[open], dialog[open]::backdrop {
  animation: open 300ms forwards;
}

@keyframes open {
  from { opacity: 0 }
  to   { opacity: 1 }
}

@keyframes close {
  from { opacity: 1 }
  to   { opacity: 0 }
}

header-dialog,
header-toggle,
cart-opener,
cart-closer {
  display: contents;
}

.showGrid {
  --column-count: 6;
  --column-width: calc((100% - (var(--column-count) - 1) * var(--gutter)) / var(--column-count));

  @media (min-width: calc(768em / 16)) {
    --column-count: 12;
  }

  @media (min-width: calc(1280em / 16)) {
    --column-count: 24;
  }

  &::after {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: calc(100% - 40px);
    height: 100%;
    margin: 0 auto;
    z-index: 9999;
    pointer-events: none;
    opacity: 0.25;
    background: repeating-linear-gradient(
      90deg,
      #ff7fff,
      #ff7fff var(--column-width),
      transparent var(--column-width),
      transparent calc(var(--column-width) + var(--gutter))
    );
  }
}

.showOutlines {
  --c1: #912eff;
  --c2: #5497ff;
  --c3: #51feff;
  --c4: #ff0000;
  --c5: #00ff00;

  > * { outline: 1px solid var(--c1); }
  > * > * { outline: 1px solid var(--c2); }
  > * > * > * { outline: 1px solid var(--c3); }
  > * > * > * > * { outline: 1px solid var(--c4); }
  > * > * > * > * > * { outline: 1px solid var(--c5); }

  > * > * > * > * > * > * { outline: 1px solid var(--c1); }
  > * > * > * > * > * > * > * { outline: 1px solid var(--c2); }
  > * > * > * > * > * > * > * > * { outline: 1px solid var(--c3); }
  > * > * > * > * > * > * > * > * > * { outline: 1px solid var(--c4); }
  > * > * > * > * > * > * > * > * > * > * { outline: 1px solid var(--c5); }

  > * > * > * > * > * > * > * > * > * > * > * { outline: 1px solid var(--c1); }
  > * > * > * > * > * > * > * > * > * > * > * > * { outline: 1px solid var(--c2); }
  > * > * > * > * > * > * > * > * > * > * > * > * > * { outline: 1px solid var(--c3); }
  > * > * > * > * > * > * > * > * > * > * > * > * > * > * { outline: 1px solid var(--c4); }
  > * > * > * > * > * > * > * > * > * > * > * > * > * > * > * { outline: 1px solid var(--c5); }
}
